'use client';

import { Stepper, StepperProps } from '@little-worker/hestia';
import { useTranslations } from 'next-intl';
import {
  PropsWithChildren, ReactNode, useEffect, useRef,
} from 'react';
import { createPortal } from 'react-dom';

import useAnimateCSS from '@hooks/useAnimateCSS';

type FormElementWrapperProps = PropsWithChildren<{
  sectionStepper?: Extract<StepperProps, { type: 'default' }>
  questionsStepper?: Extract<StepperProps, { type: 'progress' }>
  navigationButtons?: ReactNode,
  tradNamespace?: string,
  activeFormConfigSectionIndex?: number,
  activeFormSectionElementIndex?: number,
}>;

function FormElementWrapper(props: FormElementWrapperProps) {
  const {
    sectionStepper,
    questionsStepper,
    navigationButtons,
    tradNamespace,
    activeFormConfigSectionIndex,
    activeFormSectionElementIndex,
    children,
  } = props;
  const t = useTranslations();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const previousActiveFormConfigSectionIndex = useRef(activeFormConfigSectionIndex ?? 0);
  const previousActiveFormElementIndex = useRef(activeFormSectionElementIndex ?? 0);
  const animateCss = useAnimateCSS;

  useEffect(() => {
    if (
      activeFormConfigSectionIndex === undefined
      || activeFormSectionElementIndex === undefined
    ) {
      return;
    }

    if (activeFormConfigSectionIndex > previousActiveFormConfigSectionIndex.current) {
      animateCss(wrapperRef, 'fadeInRight');
      previousActiveFormConfigSectionIndex.current = activeFormConfigSectionIndex;
    } else if (activeFormConfigSectionIndex < previousActiveFormConfigSectionIndex.current) {
      animateCss(wrapperRef, 'fadeInLeft');
    } else if (activeFormSectionElementIndex > previousActiveFormElementIndex.current) {
      animateCss(wrapperRef, 'fadeInRight');
    } else if (activeFormSectionElementIndex < previousActiveFormElementIndex.current) {
      animateCss(wrapperRef, 'fadeInLeft');
    }

    previousActiveFormConfigSectionIndex.current = activeFormConfigSectionIndex;
    previousActiveFormElementIndex.current = activeFormSectionElementIndex;
  }, [activeFormSectionElementIndex, activeFormConfigSectionIndex]);

  const stepperLabelsTranslations = (tradKeys: Array<string>) => tradKeys.map(
    (tradKey) => t(`${tradNamespace}.${tradKey}`),
  );

  const header = document.getElementById('header');

  return (
    <>
      <div className="flex flex-col flex-1 w-full lg:mx-auto lg:flex-none">
        {header && createPortal((
          <div className="flex justify-between gap-500 items-center lg:w-1/2">
            {(questionsStepper || sectionStepper) && (
              <div className="flex flex-col lg:items-end w-full">
                {sectionStepper && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                  <Stepper
                    {...sectionStepper}
                    steps={stepperLabelsTranslations(sectionStepper.steps)}
                  />
                )}
                {questionsStepper && (
                  <div className="mt-150 lg:self-stretch w-full">
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Stepper {...questionsStepper} />
                  </div>
                )}
              </div>
            )}
          </div>), header as HTMLHeadElement)}

        <div ref={wrapperRef} className="animate__faster">
          {children}
        </div>
      </div>

      {navigationButtons && (
        <div className="mt-250 max-w-[718px] lg:mx-auto xl:flex xl:justify-start lg:w-full xl:mt-600">
          {navigationButtons}
        </div>
      )}
    </>
  );
}

export default FormElementWrapper;
